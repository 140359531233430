<template>
  <SingleCard />
</template>
<script>
  import SingleCard from "@/components/ZillaCards/SingleCard";
  export default {
    components: {
      SingleCard,
    },
  };
</script>
