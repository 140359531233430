<template>
  <div class="card-container">
    <BackNav @click="$router.push('/drawer/card')" />
    <div>
      <div
        class="card-image relative mx-auto mt-6"
        :class="[
          cardDetails.customization.skinColor.toLowerCase(),
          cardDetails.status === 'BLOCKED' ? 'opacity-50' : '',
        ]"
      >
        <p
          v-if="cardDetails.status === 'BLOCKED'"
          class="absolute right-2 top-3 text-darkGrey rounded-full bg-white text-xs px-3 py-2"
        >
          Blocked
        </p>
        <div class="logo-card flex justify-between items-center">
          <div class="logo">
            <img
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580773/customer/Group_13111_z0tw4h.svg"
              alt=""
            />
          </div>
          <div class="verve">
            <img
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1663583200/customer/Group_kmnfzv.png"
              alt=""
            />
          </div>
        </div>
        <img
          class="wave"
          src="https://res.cloudinary.com/zillaafrica/image/upload/v1663580774/customer/Union_1_gkjcox.png"
          alt=""
        />
      </div>
    </div>
    <!-- <Card>
      <h2 class="font-bold text-xl">$100,000</h2>
      <p class="text-grey mb-5">Card Balance</p>
      <div class="flex justify-end items-center mt-4">
        <span
          class="text-brandPurple text-sm font-bold mr-4 cursor-pointer"
          @click="showCardHistory = true"
          >History</span
        >
        <span class="text-brandPurple text-sm font-bold mr-4 cursor-pointer" @click="showWithdrawal = true">Withdraw</span>
        <SecondaryButton
          text="Fund card"
          fontSize="text-sm"
          @click="showFundCard = true"
        />
      </div>
    </Card> -->
    <div class="card-options mx-auto mt-6 flex items-center justify-between">
      <div>
        <div
          class="options-item cursor-pointer"
          :class="{ 'opacity-50': handleOpacity('Details') }"
          @click="handleOption('Details')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="16"
            fill="none"
          >
            <path
              d="M11.5.72168c-5 0-9.27 3.11-11 7.5 1.73 4.39002 6 7.50002 11 7.50002s9.27-3.11 11-7.50002c-1.73-4.39-6-7.5-11-7.5Zm0 12.50002c-2.76 0-5-2.24-5-5.00002 0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.76002-2.24 5.00002-5 5.00002Zm0-8.00002c-1.66 0-3 1.34-3 3s1.34 3.00002 3 3.00002 3-1.34002 3-3.00002c0-1.66-1.34-3-3-3Z"
              fill="#6956C8"
            />
          </svg>
        </div>
        <p class="text-center text-xs text-dark">Details</p>
      </div>
      <div>
        <div
          class="options-item cursor-pointer"
          :class="{ 'opacity-50': handleOpacity('Limit')}"
          @click="handleOption('Limit')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="18"
            fill="none"
          >
            <path
              d="m9.5 2.22168-.72-1.45c-.17-.34-.52-.55-.9-.55H1.5c-.55 0-1 .45-1 1V16.2217c0 .55.45 1 1 1s1-.45 1-1v-6h5l.72 1.45c.17.34.52.55.89.55h5.39c.55 0 1-.45 1-1V3.22168c0-.55-.45-1-1-1h-5Zm4 8.00002h-4l-1-2.00002h-6v-6h5l1 2h5v6.00002Z"
              fill="#6956C8"
            />
          </svg>
        </div>
        <p class="text-center text-xs text-dark">Limit</p>
      </div>
      <div>
        <div
          class="options-item cursor-pointer"
          :class="{ 'opacity-50': handleOpacity('Pin') }"
          @click="handleOption('Pin')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="22"
            viewBox="0 0 17 22"
            fill="none"
          >
            <path
              d="M14.5 7.22168H13.5V5.22168C13.5 2.46168 11.26 0.22168 8.5 0.22168C5.74 0.22168 3.5 2.46168 3.5 5.22168V7.22168H2.5C1.4 7.22168 0.5 8.12168 0.5 9.22168V19.2217C0.5 20.3217 1.4 21.2217 2.5 21.2217H14.5C15.6 21.2217 16.5 20.3217 16.5 19.2217V9.22168C16.5 8.12168 15.6 7.22168 14.5 7.22168ZM8.5 16.2217C7.4 16.2217 6.5 15.3217 6.5 14.2217C6.5 13.1217 7.4 12.2217 8.5 12.2217C9.6 12.2217 10.5 13.1217 10.5 14.2217C10.5 15.3217 9.6 16.2217 8.5 16.2217ZM5.5 7.22168V5.22168C5.5 3.56168 6.84 2.22168 8.5 2.22168C10.16 2.22168 11.5 3.56168 11.5 5.22168V7.22168H5.5Z"
              fill="#6956C8"
            />
          </svg>
        </div>
        <p class="text-center text-xs text-dark">Change Pin</p>
      </div>
      <div>
        <div
          class="options-item cursor-pointer"
          :class="{ 'opacity-50': handleOpacity('Freeze') }"
          @click="
            cardDetails.status === 'ACTIVE'
              ? (showBlockConfirm = true)
              : handleOption('Unfreeze')
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            fill="none"
          >
            <path
              d="M10.5.22168c-5.52 0-10 4.48-10 10.00002 0 5.52 4.48 10 10 10s10-4.48 10-10c0-5.52002-4.48-10.00002-10-10.00002Zm-8 10.00002c0-4.42002 3.58-8.00002 8-8.00002 1.85 0 3.55.63 4.9 1.69L4.19 15.1217c-1.06-1.35-1.69-3.05-1.69-4.9Zm8 8c-1.85 0-3.55-.63-4.9-1.69L16.81 5.32168c1.06 1.35 1.69 3.05 1.69 4.90002 0 4.42-3.58 8-8 8Z"
              fill="#6956C8"
            />
          </svg>
        </div>

        <p class="text-center text-xs text-dark">
          <span v-if="cardDetails.status == 'ACTIVE'">Block</span>
          <span v-else>Unblock</span>
        </p>
      </div>
    </div>
    <Card class="mt-6 card-menu mx-auto">
      <transition name="slideY" :key="currentDetail" mode="out-in">
        <component
          :is="currentDetail"
          :details="cardDetails"
          @updated="getOneCard"
        />
      </transition>
    </Card>
    <Modal
      title="Are you sure?"
      fontSize="text-xl"
      :display="showBlockConfirm"
      @close="showBlockConfirm = false"
    >
      <p class="text-grey">
        By blocking this card, you will not be able to make transactions using
        this card until you unblock the card through our support channels.
      </p>
      <!-- <p class="text-grey">
        By blocking your card, you will temporarily not be able to make
        transactions until you unblock the card.
      </p> -->
      <Button
        text="Block"
        :loading="loading"
        width="w-full"
        class="mt-6"
        @click="blockSingleCard()"
      />
      <p
        class="mt-4 text-center text-brandPurple font-semibold cursor-pointer"
        @click="showBlockConfirm = false"
      >
        Cancel
      </p>
    </Modal>
    <Modal
      title="Card history"
      fontSize="text-xl"
      titleColor="text-black"
      :display="showCardHistory"
      @close="showCardHistory = false"
    >
      <CardHistory />
    </Modal>
    <Modal
      title="Fund Account"
      fontSize="text-xl"
      titleColor="text-black"
      :display="showFundCard"
      @close="showFundCard = false"
    >
      <FundCard />
    </Modal>
    <Modal
      title="Withdrawal"
      fontSize="text-xl"
      titleColor="text-black"
      :display="showWithdrawal"
      @close="showWithdrawal = false"
    >
      <Withdrawal />
    </Modal>
  </div>
</template>
<script>
import { BackNav } from "@/UI/Navigation";
import { mapActions } from "vuex";
import { SecondaryButton, Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import CardHistory from "./CardHistory.vue";
import FundCard from "./FundCard.vue";
export default {
  components: {
    Details: () => import("./Details.vue"),
    Limit: () => import("./Limit.vue"),
    Pin: () => import("./Pin.vue"),
    Unfreeze: () => import("./Unfreeze.vue"),
    BackNav,
    SecondaryButton,
    Modal,
    Button,
    CardHistory,
    FundCard,
    Withdrawal: () => import("./WithdrawFunds.vue"),
  },
  data: () => ({
    currentDetail: "Details",
    cardDetails: {
      customization: {
        skinColor: "purple-icon",
      },
    },
    showBlockConfirm: false,
    showCardHistory: false,
    showFundCard: false,
    loading: false,
    showWithdrawal: false,
  }),
  mounted() {
    this.getOneCard();
  },
  methods: {
    ...mapActions("card", ["getSingleCard", "blockCard"]),
    ...mapActions("notification", ["showAlert"]),
    handleOption(option) {
      this.currentDetail = option;
    },

    handleOpacity(step) {
      return this.currentDetail !== step;
    },

    getOneCard() {
      this.getSingleCard(this.$route.params.id)
        .then((res) => {
          this.cardDetails = res.data.data;
        })
        .catch(() => {});
    },

    blockSingleCard() {
      this.loading = true;
      this.blockCard(this.cardDetails.id)
        .then((res) => {
          this.loading = false;
          this.showBlockConfirm = false;
          this.showAlert({
            display: true,
            type: "success",
            description: `Card blocked successfully`,
          });
          this.getOneCard();
          this.currentDetail = "Details";
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.card-container {
  max-width: 36.563rem;
  margin: auto;
}
.card-image-container {
  max-width: 21rem;
}
.card-image {
  position: relative;
  /* display: grid; */
  margin-top: 2.75rem;
  height: 14rem;
  max-width: 21rem;
  border-radius: 19px;
  transition: all 0.3s linear;
  box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.03), 0px 6px 4px rgba(0, 0, 0, 0.09),
    0px 3px 3px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.17),
    0px 0px 0px rgba(0, 0, 0, 0.18);
  overflow: hidden;
  /* filter: drop-shadow(0px 77px 31px rgba(0, 0, 0, 0.03))
      drop-shadow(0px 43px 26px rgba(0, 0, 0, 0.11))
      drop-shadow(0px 19px 19px rgba(0, 0, 0, 0.19))
      drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.21))
      drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.22)); */
}
.customize--card {
  max-width: 21rem;
  margin: auto;
}

.card-image .wave {
  position: absolute;
  bottom: 0;
}

.card-image .verve {
  width: 3.875rem;
  height: auto;
}
.card-image .logo {
  width: 6.875rem;
  height: auto;
}
.logo-card {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  right: 1rem;
  z-index: 10;
}
.color-choice {
  max-width: 14.26rem;
  margin: 1rem auto 0 auto;
}
.color-choice .box {
  height: 2rem;
  width: 2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.03), 0px 6px 4px rgba(0, 0, 0, 0.09),
    0px 3px 3px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.17),
    0px 0px 0px rgba(0, 0, 0, 0.18);
}
.black {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579293/customer/ZCard00_1_oz6i2c.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.purple {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663344397/customer/ZCard01_2_npv5dd.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.red {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579250/customer/ZWall01_Color_corrected_2_eo7hht.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.blue {
  background-image: url("https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1663579244/customer/Card_D00_1_jjizgb.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.purple-icon {
  background: linear-gradient(
      111.74deg,
      #c6003b -198.45%,
      #6a30c3 22.71%,
      #63dfdf 191.46%
    ),
    #6a30c3;
}
.card-options {
  width: 19.75rem;
}
.options-item {
  background: white;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: all 0.5s linear;
  /* box-shadow: 0px 11px 4px rgba(0, 0, 0, 0.03),
      0px 6px 4px rgba(0, 0, 0, 0.09), 0px 3px 3px rgba(0, 0, 0, 0.15),
      0px 1px 1px rgba(0, 0, 0, 0.17), 0px 0px 0px rgba(0, 0, 0, 0.18); */
}
/* .card-menu {
  max-width: 26.188rem;
} */
</style>