<template>
  <div>
    <div>
      <!-- date filter -->
      <div class="width w-48 ml-auto">
        <DatePicker
          class="mr-2"
          v-model="range"
          is-range
          :model-config="modelConfig"
          :masks="masks"
          ref="calendar"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <Card
              class="flex items-center border border-lightGrey-3"
              p="px-3 py-4"
            >
              <div
                @click="togglePopover()"
                class="flex w-full items-center relative justify-between cursor-pointer"
                v-if="inputValue.start === null"
              >
                <p class="text-grey text-sm">Select a date</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="16"
                  fill="none"
                >
                  <path
                    d="M12.3335 2.00033h-.6667v-.66667c0-.36667-.3-.66667-.6666-.66667-.3667 0-.6667.3-.6667.66667v.66667H3.66683v-.66667c0-.36667-.3-.66667-.66667-.66667-.36666 0-.66666.3-.66666.66667v.66667h-.66667c-.73333 0-1.33333.6-1.33333 1.33333V14.0003c0 .7334.6 1.3334 1.33333 1.3334H12.3335c.7333 0 1.3333-.6 1.3333-1.3334V3.33366c0-.73333-.6-1.33333-1.3333-1.33333Zm-.6667 11.99997H2.3335c-.36667 0-.66667-.3-.66667-.6666V5.33366H12.3335v8.00004c0 .3666-.3.6666-.6667.6666Z"
                    fill="#1E1452"
                  />
                </svg>
              </div>

              <p v-else @click="togglePopover()" class="text-sm text-grey">
                {{ inputValue.start }} - {{ inputValue.end }}
              </p>
            </Card>
          </template>
        </DatePicker>
      </div>
      <div class="container-body scrollbar p-2">
        <transition name="fade" mode="out-in">
          <div v-if="loading" key="load">
            <div v-for="i in 5" :key="i">
              <div class="grid grid-cols-2">
                <div>
                  <Skeleton height="1rem" width="70%" />
                  <Skeleton height="0.8rem" width="90%" />
                  <!-- <p class="text-sm">12th Dec. 2021 | 10.00am</p> -->
                </div>
                <div class="flex flex-col items-end">
                  <Skeleton height="1rem" width="70%" />
                  <Skeleton height="0.8rem" width="90%" />
                </div>
              </div>
              <Hr class="my-4" />
            </div>
          </div>
          <div v-else key="data">
            <EmptyState
              description="No funding history to display"
              v-if="!walletHistory.length"
            />
            <div v-for="wallet in walletHistory" :key="wallet.id" v-else>
              <div class="grid grid-cols-2 content-center my-5">
                <div>
                  <p class="font-semibold text-grey">
                    {{ wallet.walletActivityType | capitalize }}
                  </p>
                  <p class="text-xs text-grey uppercase mt-1">
                    {{ dayjs(wallet.createdAt).format("D MMM YYYY | hh:mmA") }}
                  </p>
                </div>
                <div class="flex flex-col items-end justify-center">
                  <p
                    class="font-bold"
                    :class="[
                      wallet.walletActivityType.toLowerCase() === 'credit'
                        ? 'font-black'
                        : 'failed',
                    ]"
                  >
                    {{ wallet.amount | formatMoney }}
                  </p>
                </div>
              </div>
              <!-- <Hr class="my-2" /> -->
            </div>
            <Pagination
              :handleMore="handleMore"
              :loading="paginateLoading"
              @paginate="handlePagination"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
  <!-- </AnimateHeight> -->
</template>
<script>
import { mapActions, mapState } from "vuex";
import Hr from "@/UI/Hr";
import dayjs from "dayjs";
import AnimateHeight from "@/UI/AnimateHeight";
import { DatePicker } from "v-calendar";
import { Button, SecondaryButton } from "@/UI/Button";
export default {
  components: {
    Hr,
    EmptyState: () => import("@/UI/EmptyState"),
    Pagination: () => import("@/UI/Pagination"),
    AnimateHeight,
    DatePicker,
    Button,
    SecondaryButton,
  },
  data: () => ({
    loading: false,
    paginateLoading: false,
    handleMore: false,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    params: {
      page: 0,
      pageSize: 8,
      from: "",
      to: "",
    },
  }),
  props: {
    balance: {
      type: Number,
      default: 0,
      required: false,
    },
    creditBalance: {
      type: Number,
      default: 0,
      required: false,
    },
    showBalance: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    ...mapState({
      walletHistory: (state) => state?.dashboard?.instaPayWalletHistory,
      customerTodos: (state) => state?.dashboard?.dashboardData?.customerTodos,
    }),
    eligibletoWithdraw() {
      return this.customerTodos.some((item) => {
        return item.key === "IDENTITY_VERIFICATION";
      });
    },
    dayjs() {
      return dayjs;
    },
  },
  beforeMount() {
    this.getWalletHistory();
  },
  methods: {
    ...mapActions("dashboard", ["getInstaWalletHistory"]),
    getWalletHistory(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getInstaWalletHistory({ type: type, params: this.params })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getWalletHistory("paginate");
    },
    handleSearch() {
      this.params.page = 0;
      this.params.from = this.range.start;
      this.params.to = this.range.end;
      this.getWalletHistory();
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
@import "~@/assets/styles/table.css";
.container-body {
  min-height: 40vh;
  overflow: auto;
}
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}

.status-container {
  border: 5px;
  background-color: #f5f5f5;
}
</style>
