<template>
  <div class="default-layout-container">
    <div class="h-full w-full grid">
      <div>
        <p class="text-grey">
          You can fund your dollar card by using money from your wallet.
        </p>
        <CurrencyInput
          placeholder="Amount in Naira"
          width="w-full"
          class="mt-5"
          :showCurrency="true"
          v-model="amountInNaira"
        />
        <div class="flex justify-between text-sm mb-4 mt-1">
          <p
            class="text-sm"
            :class="
              amountInNaira > instaPayWallet ? 'text-brandRed' : 'text-grey'
            "
          >
            Wallet amount =
            <span v-if="instaPayWallet">{{
              instaPayWallet | formatMoney
            }}</span>
            <span v-else>{{ 0 | formatMoney }}</span>
          </p>
          <span
            class="text-brandPurple cursor-pointer"
            @click="showFundAccount = true"
            >Fund wallet</span
          >
        </div>
        <CurrencyInput
          placeholder="Dollar equivalent"
          width="w-full"
          class="mt-8"
          symbol="$"
          :showCurrency="true"
          v-model="amountInDollars"
        />
        <p class="text-sm mt-1 text-blue">
          At the rate of $1 = {{ rate | formatMoney }}
        </p>
      </div>
      <div class="flex items-end mt-4">
        <div class="w-full">
          <div class="spam-notice mb-4 mt-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                fill="#0EA2F5"
              />
            </svg>
            <p class="text-sm text-grey w-full">
              Funding guide:
              <br />
              Minimum top up= $5
              <br />
              Maximum top up= $250
            </p>
          </div>
          <Button class="w-full" text="Fund account" />
        </div>
      </div>
    </div>
    <Modal
      :display="showFundAccount"
      @close="closeFundWallet"
      title="Add money to account"
    >
      <FundAccount @completed="completed" @close="showFundAccount = false" />
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { CurrencyInput, Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
export default {
  components: {
    Input,
    CurrencyInput,
    Button,
    Modal,
    FundAccount: () => import("@/components/Home/FundAccount"),
  },
  data: () => ({
    amountInNaira: 0,
    amountInDollars: 0,
    rate: 670,
    showFundAccount: false,
  }),
  computed: {
    ...mapState({
      instaPayWallet: (state) =>
        state?.dashboard?.userWallet?.instaPayWallet?.balance,
    }),
  },
  watch: {
    amountInNaira(value) {
      this.amountInDollars = value / this.rate;
    },
    // convert dollars to naira
    amountInDollars(value) {
      this.amountInNaira = value * this.rate;
    },
  },
  mounted() {
    this.getUserWallet();
  },
  methods: {
    ...mapActions("dashboard", ["getUserWallet"]),
    ...mapActions("notification", ["showAlert"]),
    completed() {
      this.showFundAccount = false;
      this.showAlert({
        display: true,
        type: "success",
        description: `Wallet successfully funded`,
      });
    },

    closeFundWallet() {
      this.getUserWallet();
      this.showFundAccount = false;
    },
  },
};
</script>

<style scoped>
.default-layout-container {
  height: calc(70vh - 6rem);
  max-height: 50rem;
  overflow: hidden;
}
</style>
