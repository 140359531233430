// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!@/assets/styles/animation.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-1-1!../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-1-2!@/assets/styles/table.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".container-body[data-v-7d16006b] {\n  min-height: 40vh;\n  overflow: auto;\n}\n.searchContainer[data-v-7d16006b] {\n  border: 1px solid rgba(138, 116, 192, 0.1) !important;\n  width: 12rem;\n}\n.status-container[data-v-7d16006b] {\n  border: 5px;\n  background-color: #f5f5f5;\n}\n", ""]);
// Exports
module.exports = exports;
